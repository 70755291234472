<template>
  <v-list>
    <v-subheader>Organisator</v-subheader>

    <v-list-item>
      <v-list-item-avatar v-if="owner.avatar">
        <v-img :src="owner.avatar.url"></v-img>
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-title>
          {{ owner.name }}
          <v-chip
            v-if="owner.id === $store.state.user.id"
            outlined
            small
            class="ml-1 mb-1"
            >Sie</v-chip
          >
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-subheader v-if="value.length > 0">Teilnehmer</v-subheader>

    <draggable :value="value" @input="$emit('input', $event)">
      <transition-group type="transition" name="flip-list">
        <v-list-item
          v-for="participant in participants"
          :key="participant.key"
          style="cursor: move"
          two-line
        >
          <v-badge
            bottom
            overlap
            :color="participant.badge.color"
            offset-x="28"
            offset-y="28"
          >
            <template v-slot:badge v-if="participant.badge.icon">
              <v-icon :title="participant.badge.title">{{ participant.badge.icon }}</v-icon>
            </template>
            <v-list-item-avatar style="margin-left: 0">
              <v-img
                v-if="participant.type === 'user'"
                :src="participant.avatar.url"
              ></v-img>
              <v-icon v-else>$profile</v-icon>
            </v-list-item-avatar>
          </v-badge>

          <v-list-item-content>
            <v-list-item-title>
              {{ participant.name }}
              <v-chip
                v-if="participant.userId === $store.state.user.id"
                outlined
                small
                class="ml-1 mb-1"
                >Sie</v-chip
              >
            </v-list-item-title>
            <v-list-item-subtitle v-if="participant.type === 'contact'">{{
              participant.email
            }}</v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action class="d-flex flex-row">
            <share-link-copy-button
              class="mr-2"
              v-if="!readonly && participant.type === 'contact'"
              v-model="participant.shareLinkUuid"
            >
            </share-link-copy-button>

            <v-select
              :items="scopes"
              :readonly="readonly"
              class="pb-1"
              hide-details
              style="max-width: 122px"
              v-model="participant.scope"
              @change="changeScope(participant, $event)"
            ></v-select>

            <temeno-circle-button
              action
              icon
              class="ml-2"
              title="Teilnehmer entfernen"
              v-if="!readonly"
              @click="remove(participant)"
            >
              $delete
            </temeno-circle-button>
          </v-list-item-action>
        </v-list-item>
      </transition-group>
    </draggable>
  </v-list>
</template>

<script>
export default {
  name: "ParticipantList",
  components: {
    draggable: () => import("vuedraggable"),
    ShareLinkCopyButton: () => import("@/components/ShareLinkCopyButton.vue"),
    TemenoCircleButton: () => import("@/components/TemenoCircleButton.vue"),
  },
  props: {
    isNewConference: {
      type: Boolean,
      default: false,
    },
    owner: Object,
    readonly: {
      type: Boolean,
      default: false,
    },
    value: Array,
  },
  computed: {
    participants() {
      return this.value.map((p) => {
        const badge = this.getBadge(p);
        return { badge, ...p };
      });
    },
    scopes() {
      return [
        {
          text: "Teilnehmer",
          value: "JOIN",
        },
        {
          text: "Moderator",
          value: "MODERATE",
        },
      ];
    },
  },
  methods: {
    changeScope(participantToChange, scope) {
      const copy = [...this.value];
      copy.forEach((p) => {
        if (p.key === participantToChange.key) {
          p.scope = scope;
        }
      });
      this.$emit("input", copy);
    },
    getBadge(participant) {
      if (this.isNewConference) {
        return {
          color: "transparent",
        }
      }
      switch (participant.reply) {
        case "ACCEPTED":
          return {
            color: "success",
            icon: "mdi-check",
            title: `${participant.name} hat die Teilnahme bestätigt`,
          };
        case "TENTATIVE":
          return {
            color: "warning",
            icon: "mdi-circle-medium",
            title: `${participant.name} hat die Teilnahme unter Vorbehalt bestätigt`,
          };
        case "DECLINED":
          return {
            color: "error",
            icon: "mdi-close",
            title: `${participant.name} hat die Teilnahme abgelehnt`,
          };
        default:
          return {
            color: "#14415F",
            icon: "mdi-account-question",
            title: `${participant.name} hat noch nicht geantwortet`,
          };
      }
    },
    remove(participantToRemove) {
      const filtered = this.value.filter(
        (p) => p.key !== participantToRemove.key
      );
      this.$emit("input", filtered);
    },
  },
};
</script>

<style scoped>
.v-list {
  width: 100%;
}
</style>
